import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import IconBlack from "../images/icon-black.png"
import Border from "../images/border.svg"
import BackBook from "../images/low-back-pain-book.png"
import BackBookCropped from "../images/low-back-pain-book-2.jpg"
import LowBackOne from "../images/low-back-1.jpg"
import VideoExample from "../images/video-example.png"
import BookOpen from "../images/book-open.png"
import Icon1 from "../images/icon-1.svg"
import Icon2 from "../images/icon-2.svg"
import Icon3 from "../images/icon-3.svg"
import Icon4 from "../images/icon-4.svg"
import Icon5 from "../images/icon-5.svg"
import Icon6 from "../images/icon-6.svg"
import Icon7 from "../images/icon-7.svg"
import Icon8 from "../images/icon-8.svg"
import ReviewLowBack from "../components/review-low-back";
import WhoWeAre from "../images/who-we-are.jpg"
import Pricing from "../components/pricing";
import {Helmet} from "react-helmet";
import { useCollapse } from 'react-collapsed'

function Collapsible(props) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

    return (
        <div className={"py-2 lg:pl-0 pl-2 border-t-2 border-accent/50"} {...getToggleProps()}>
            <h2 className={"lg:text-xl text-xl font-bold p-2 flex gap-2 justify-between items-center "} >
                {props.title}
                {isExpanded ?
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
                    :
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                }

            </h2>
            <section  {...getCollapseProps()}>
                {props.children}
            </section>
        </div>
    )
}

function LowBackProgram() {
    const { getCollapseProps, getToggleProps, isExpanded, } = useCollapse()
    return (
        <>
            <Helmet>
                <title>Kinetic Impact | Low Back Program</title>
            </Helmet>
            {/*bg-gradient-to-r from-accent to-[#20a264]*/}
            <div className={"bg-gradient-to-r from-accent to-[#20a264]"}>
                <Nav/>
                <div className={"max-w-5xl mx-auto text-white flex flex-col align-middle items-center mx-auto"}>
                {/*<img className={"w-40 py-16"} src={IconBlack} alt={"icon"}/>*/}
                    <div className={"pt-10 lg:pt-20"}></div>

                    <div className={"relative max-w-2xl"}>
                        <div className={"absolute w-full lg:-top-5 -top-3 flex flex-col align-middle items-center"}>
                            <p className={"lg:text-5xl text-2xl font-bold text-center pb-6"}>LOWER BACK PAIN?</p>
                            <p className={"lg:text-7xl text-4xl font-bold text-center"}>IT'S TIME TO GET BACK IN THE GAME</p>
                        </div>
                        <img className={"w-full"} src={Border} alt={"border"} />
                    </div>
                    <div className={"max-w-2xl mx-auto p-10"}>
                    {/*<p className={"text-lg py-4"}>Conquering Low Back Pain is the culmination of routines that we have used to help thousands of people just like you who were suffering from low back pain all packaged up into one E-book. </p>*/}
                    {/*<p className={"text-lg py-4 pb-8"}>This is a 10 week program with daily drills laid out for you every step of the way.</p>*/}
                        <div className={"flex justify-center"}>
                    <a className={"mx-auto"} href={"#pricing"}><button className={"mx-auto bg-white text-black px-12 py-3 hover:bg-gray-200 text-xl"}>Get the Program!</button></a></div>
                        <div className={"flex py-10"}>
                        <img className={"w-1/3"} src={BackBookCropped} srcSet={"back pain book"} />
                        <img className={"w-2/3"} src={VideoExample} alt={"video example"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div>

                <div className={"text-white  text-xl  pt-5  bg-[url('../images/low-back-1.png')] bg-cover bg-left bg-no-repeat w-full"} >
                    {/*<img className={"absolute left-0 -z-10"} src={LowBackOne} alt={"stretching image"} />*/}
                    {/*<div className={"absolute bg-black h-[1000px] w-full -z-20"}></div>*/}
                    <div className={"mx-auto max-w-4xl"}>
                        <Collapsible title={"Who is This For?"}>
                            <p className={"p-4 lg:pr-4 pr-12"}>This e-book is for someone with an acute episode of lower back pain and is looking to get back into being physically active. If you are new to working out or exercising, it can work as a mini workout that can kick start you into more challenging routines. If you are an experienced exerciser, this works as a great bridge and/or warm up to your workout. </p>
                            <a href={"#pricing"}><button className={"bg-white text-black px-12 py-3 hover:bg-gray-200 text-xl"}>Get the Program!</button></a>
                        </Collapsible>
                        <Collapsible title={"10 Weeks of Routines to Get You Back in the Game"}>
                            <p className={"p-4 lg:pr-4 pr-12"}>Conquering Low Back Pain is the culmination of routines that we have used to help thousands of people just like you who were suffering from an episode of low back pain. This is a 10 week program with some of our favorite daily drills laid out for you every step of the way. </p>

                            <a href={"#pricing"}><button className={"bg-white text-black px-12 py-3 hover:bg-gray-200 text-xl"}>Get the Program!</button></a>
                        </Collapsible>
                        <Collapsible title={"Video Walkthrough of Each Routine"}>
                            <p className={"text-xl pb-10"}>You will have access to video demonstrations of each exercise filtered down to a series of routines that are aimed to get you feeling better in just 10-15 minutes a day. The program is gentle, low impact and can benefit everybody from the professional athletes to the desk warrior.</p>
                            <a  href={"#pricing"}><button className={"bg-accent text-white px-12 py-3 ml-4 hover:bg-gray-100 text-xl"}>Get the Program!</button></a>
                        </Collapsible>
                        <Collapsible title={"Who is this NOT For?"}>
                            <p className={"p-4 text-xl"}>This program does NOT take into consideration your current activity levels, your individual goals and a plan of action to help get you there. Yes, they are great to kick start something or to explore and find some gentle feel-goods but they do not provide a long term strategy. If you have specific goals and want more individual attention with an interactive game plan, then online programming is more up your alley. We do have limited spots to work directly with one of our clinicians/strength coaches. <a className={"text-accent"} href={"mailto:info@kirehab.com"}>Please email us here</a> if you’d like to make that move. </p>
                        </Collapsible>

                    </div>

                </div>
            </div>
            {/*<div className={"bg-gray-100 flex flex-wrap align-middle items-center justify-evenly lg:py-10 py-5"}>*/}
            {/*    <div className={"lg:w-1/2 p-4 lg:p-0"}>*/}
            {/*        <Collapsible title={"Video Walkthrough of Each Routine"}>*/}
            {/*        <p className={"text-xl pb-10"}>You will have access to video demonstrations of each exercise filtered down to a series of routines that are aimed to get you feeling better in just 10-15 minutes a day. The program is gentle, low impact and can benefit everybody from the professional athletes to the desk warrior.</p>*/}
            {/*        <a  href={"#pricing"}><button className={"bg-accent text-white px-12 py-3 ml-4 hover:bg-gray-100 text-xl"}>Get the Program!</button></a>*/}
            {/*        </Collapsible>*/}
            {/*        <Collapsible title={"Who is this NOT For?"}>*/}
            {/*            <p className={"p-4 text-xl"}>This program does NOT take into consideration your current activity levels, your individual goals and a plan of action to help get you there. Yes, they are great to kick start something or to explore and find some gentle feel-goods but they do not provide a long term strategy. If you have specific goals and want more individual attention with an interactive game plan, then online programming is more up your alley. We do have limited spots to work directly with one of our clinicians/strength coaches. <a className={"text-accent"} href={"mailto:info@kirehab.com"}>Please email us here</a> if you’d like to make that move. </p>*/}
            {/*        </Collapsible>*/}
            {/*    </div>*/}
            {/*    <img className={"w-fit lg:w-1/3 p-4"} src={VideoExample} alt={"video example"}/>*/}
            {/*</div>*/}
            {/*<div className={"bg-gray-100 m-auto text-center"}>*/}
            {/*<Collapsible title={"Video Walkthrough of Each Routine"}>*/}
            {/*    <div className={"bg-gray-100 flex flex-wrap align-middle items-center justify-evenly py-10"}>*/}
            {/*        <div className={"max-w-xl"}>*/}
            {/*            <p className={"p-4 text-xl"}>You will have access to video demonstrations of each exercise filtered down to a series of routines that are aimed to get you feeling better in just 10-15 minutes a day. The program is gentle, low impact and can benefit everybody from the professional athletes to the desk warrior.</p>*/}
            {/*            <a href={"#pricing"}><button className={"bg-accent text-white px-12 py-3 ml-4 hover:bg-gray-100 text-xl"}>Get the Program!</button></a>*/}
            {/*        </div>*/}
            {/*        <img className={"w-fit lg:w-1/3 p-4"} src={VideoExample} alt={"video example"}/>*/}
            {/*    </div>*/}
            {/*</Collapsible>*/}
            {/*</div>*/}
            {/*<div className={"bg-gray-100 m-auto text-center"}>*/}
            {/*    <Collapsible title={"Who is this NOT For?"}>*/}
            {/*        <div className={"bg-gray-100 flex flex-wrap align-middle items-center justify-evenly py-10"}>*/}
            {/*            <div className={"max-w-xl"}>*/}
            {/*                <p className={"p-4 text-xl"}>This program does NOT take into consideration your current activity levels, your individual goals and a plan of action to help get you there. Yes, they are great to kick start something or to explore and find some gentle feel-goods but they do not provide a long term strategy. If you have specific goals and want more individual attention with an interactive game plan, then online programming is more up your alley. We do have limited spots to work directly with one of our clinicians/strength coaches. <a className={"text-accent"} href={"mailto:info@kirehab.com"}>Please email us here</a> if you’d like to make that move. </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Collapsible>*/}
            {/*</div>*/}

            {/*<div className={"bg-gray-100 flex flex-wrap align-middle items-center justify-evenly py-20"}>*/}
            {/*    <img className={"lg:h-128 p-4"} src={BookOpen} alt={"video example"}/>*/}
            {/*    <div className={"max-w-xl"}>*/}
            {/*        <h2 className={"text-4xl font-bold p-4"}>Video Walkthroughs of Each Routine</h2>*/}
            {/*        <p className={"p-4 text-xl"}>These routines consist of hip mobility and core activation drills designed to decrease tension in the lumbar spine and help you build a more resilient low back. This is all our best content filtered down to a series of effective movements to get you feeling better in just 10-15 minutes a day. </p>*/}
            {/*        <p className={"p-4 text-xl"}>The program is gentle, low impact and can benefit everybody from the professional athletes to the desk warriors.</p>*/}
            {/*        <a href={"#pricing"}><button className={"bg-accent text-white px-12 py-3 ml-4 hover:bg-gray-100 text-xl"}>Get the Program!</button></a>*/}
            {/*    </div>*/}
            {/*    <div></div>*/}
            {/*</div>*/}
            <div className={"bg-accent py-10"}>
                <h2 className={"text-4xl text-center p-8"}>Here’s everything you’ll get access to:</h2>
                <div className={"flex flex-row flex-wrap justify-between items-center align-middle max-w-5xl mx-auto font-bold text-lg"}>
                <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center"}>
                    <img className={"h-12"} src={Icon1} alt={'icon'}/>
                    <p>10 weeks of Daily Routines</p>
                </div>
                    <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center"}>
                        <img className={"h-12"} src={Icon2} alt={'icon'}/>
                        <p>5 Phases of Progressions</p>
                    </div>
                    <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center"}>
                        <img className={"h-12"} src={Icon3} alt={'icon'}/>
                        <p>Organized Daily Routines</p>
                    </div>
                    <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center"}>
                        <img className={"h-12"} src={Icon4} alt={'icon'}/>
                        <p>Demo Videos linked to every exercise</p>
                    </div>
                    <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center"}>
                        <img className={"h-12"} src={Icon5} alt={'icon'}/>
                        <p>Built in accountability measures</p>
                    </div>
                    <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center"}>
                        <img className={"h-12"} src={Icon6} alt={'icon'}/>
                        <p>Checklist to mark each day complete</p>
                    </div>
                    <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center"}>
                        <img className={"h-12"} src={Icon7} alt={'icon'}/>
                        <p>Notes section</p>
                    </div>
                    <div className={"lg:w-1/3 p-2 lg:p-4 flex flex-row gap-2 items-center lg:mr-auto"}>
                        <img className={"h-12"} src={Icon8} alt={'icon'}/>
                        <p>All packaged up for you in one downloadable e-book!</p>
                    </div>

                </div>


            </div>
            <Pricing/>
            {/*<div className={"bg-white"}>*/}
            {/*    <h2 className={"text-center text-4xl pt-16"}>Check Out What Others Have Said:</h2>*/}
            {/*    <ReviewLowBack/>*/}


            {/*</div>*/}
            <div className={"bg-white"}>
            <div className={"flex flex-col justify-center items-center py-16 max-w-5xl mx-auto"}>
                <h1 className={"text-4xl"}>Who We Are:</h1>
                <p className={"py-16 text-xl text-center max-w-2xl"}>We are Kinetic Impact and we have over 30 years of experience in rehab care and strength training. It is our vision to provide simple and effective movement solutions that lift physical limitations. We are passionate about helping you go from in pain and frustrated to confident and in control of your symptoms.</p>
                <img src={WhoWeAre} alt={"who we are"} />
            </div>
            </div>
            <Footer/>
        </>
    )
}

export default LowBackProgram